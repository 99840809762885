<template>
  <!-- <leftFloat :tabList="tabList" @currentChange="currentChange" /> -->
  <div class="view-open-mod">
    <div :style="Opacity0" class="header-box">
      <div @click="$router.go(-1)" class="back-box"></div>
      <div>{{ ItemInfo.name }}</div>
      <div style="width: 0.25rem; height: 0.14rem"></div>
    </div>
    <div class="center-box">
      <!-- 每日一箱  不显示弹药模式 -->
      <div
        :style="Opacity0"
        v-if="
          List.length && !(isPointBox || isCdkeyBox) && this.cateIdOneDay !== 38
        "
        @click="openDanyao = !openDanyao"
        class="float-opens-btn-box flex"
      >
        <img
          v-if="!openDanyao"
          style="width: 0.3rem; margin-right: 0.04rem"
          src="../../assets/images/new/danyao-bg3.png"
          alt=""
          srcset=""
        />
        <img
          v-else
          style="width: 0.3rem; margin-right: 0.04rem"
          src="../../assets/images/new/danyao-bg4.png"
          alt=""
          srcset=""
        />
        <div style="font-size: 0.12rem; color: #7373c0">弹药模式</div>
      </div>
      <div
        :style="Opacity0"
        class="float-sound-box point"
        title="声音开启或关闭"
      >
        <img
          src="../../assets/images/OpenBox/shengyin1.png"
          v-show="soundStatus"
          @click="mp3PlayOnClick"
          alt=""
          srcset=""
        />
        <img
          src="../../assets/images/OpenBox/shengyin2.png"
          v-show="!soundStatus"
          @click="mp3PlayOnClick"
          alt=""
          srcset=""
        />
      </div>
      <div
        :style="Opacity0"
        class="float-animation-box point"
        title="动画开启或关闭"
      >
        <img
          src="../../assets/images/OpenBox/donghua1.png"
          v-show="!Animation_checked"
          @click="() => (this.Animation_checked = true)"
          alt=""
          srcset=""
        />
        <img
          src="../../assets/images/OpenBox/donghua2.png"
          v-show="Animation_checked"
          class="dhbtn"
          @click="() => (this.Animation_checked = false)"
          alt=""
          srcset=""
        />
      </div>
      <div class="float-black-box" v-show="luckdrawShow"></div>
      <div class="good-img">
        <goods
          class="rotate-goods-box"
          :pic="ItemInfo.weapon_cover"
          :cover="ItemInfo.cover"
          v-show="luckdrawShow == false"
        ></goods>
        <template v-if="active > 5">
          <luckdrawY
            :ItemData="ItemData"
            :givingItemData="givingItemData"
            :CSnum="CSnum"
            :active="active"
            :List="List"
            @zhongjiang="GetUnpackingRecord"
            v-show="luckdrawShow"
            @fromChild="fromChild"
          ></luckdrawY>
        </template>
        <template v-else>
          <luckdrawX
            :ItemData="ItemData"
            :givingItemData="givingItemData"
            :CSnum="CSnum"
            :active="active"
            :List="List"
            v-show="luckdrawShow"
            @zhongjiang="GetUnpackingRecord"
            @fromChild="fromChild"
          >
          </luckdrawX>
        </template>
      </div>
    </div>

    <!-- 每日一箱  不显示数字盒子 -->
    <div
      :style="Opacity0"
      v-if="List.length && !isCdkeyBox && this.cateIdOneDay !== 38"
      class="num-box"
    >
      <div
        v-for="item in [1, 2, 3, 4, 5]"
        :key="item"
        @click="changeActiveOnClick(item)"
        class="num-box-item point"
        :class="{ 'num-box-item-active': active === item }"
      >
        X{{ item }}
      </div>
    </div>
    <div :style="Opacity0" v-if="isCdkeyBox" class="cdkey-box">
      <div class="input-box">
        <el-input
          maxlength="60"
          v-model="Cdkey"
          placeholder="请输入CDKEY"
        ></el-input>
      </div>
      <div class="flex-c" style="width: 100%; margin-top: 0.1rem">
        <div style="width: 3.06rem; font-size: 0.1rem; color: #c5bbed">
          通过在各官方主播直播间内获取的福利宝箱CDKEY后，将此码填入输入框之后即可开启宝箱，无需充值，即开即取。
        </div>
      </div>
      <!-- <div class="tips"></div> -->
    </div>
    <div class="open-btn-box">
      <div
        :style="Opacity0"
        v-if="List.length && this.is_open != false"
        @click="openOnClick"
        class="open-btn flex-c point"
      >
        <div v-if="openDanyao" class="flex-c">
          <img
            style="width: 0.2rem"
            src="../../assets/images/new/danyao-bg6.png"
            alt=""
            srcset=""
          />
          <div style="margin-left: 0.06rem">弹药发射</div>
        </div>
        <div v-else-if="isPointBox" class="flex-c">
          <Cintegral :size="0.14" :price="openTotalPrice" />
          <div style="margin-left: 0.06rem">打开</div>
        </div>
        <div v-else-if="isCdkeyBox" class="flex-c">
          <div style="margin-left: 0.06rem">开启</div>
        </div>

        <div v-else class="flex-c">
          <Cprice :size="0.14" :price="openTotalPrice" />
          <div style="margin-left: 0.06rem">打开</div>
        </div>
      </div>
      <div v-if="this.is_open == false" class="open-btn flex-c closebox">
        <div class="flex-c">
          <div style="margin-left: 0.06rem">今日已开箱</div>
        </div>
      </div>
      <!-- <div
        v-if="isCdkeyBox"
        :style="Opacity0"
        @click="showRarityBox2 = !showRarityBox2"
        class="rarity-box"
        style="padding: 0; position: absolute; right: 0.62rem; top: 0.1rem"
      >
        <div class="rarity-text-box flex-c">
          <div class="right-icon" style="width: 0.16rem; height: 0.16rem"></div>
        </div>
        <div v-if="showRarityBox2" class="float-text" style="right: -0.3rem">
          <div class="icon-img"></div>
          通过在各主播直播间内获取的新人宝箱CDK后，将此码填入输入框之后即可开启宝箱
        </div>
      </div> -->
      <div
        v-if="openDanyao"
        @click="chooseGoodsOnClick"
        class="left-icon-box flex-c"
      >
        <div class="common-left-icon-box">
          <div class="left-circle">
            <div
              :style="`--d:${360 - svgCircleAngle1}deg`"
              class="common-test-circle"
            ></div>
          </div>

          <div class="title">饰品注能</div>
          <div v-if="svgCircleAngle1 < 180" class="sactor-mask"></div>
          <div
            v-else
            class="sactor-mask"
            :style="`background: rgba(0, 0, 0, ${
              (360 - svgCircleAngle1) / 360
            }`"
          ></div>
        </div>
      </div>
      <div v-if="openDanyao" class="right-icon-box flex-c">
        <div class="common-right-icon-box">
          <div class="right-circle">
            <div
              :style="`--d:${360 - svgCircleAngle2}deg`"
              class="common-test-circle"
            ></div>
          </div>
          <div class="title">弹药注能</div>
          <div v-if="svgCircleAngle2 < 180" class="sactor-mask"></div>
          <div
            v-else
            class="sactor-mask"
            :style="`background: rgba(0, 0, 0, ${
              (360 - svgCircleAngle2) / 360
            }`"
          ></div>
        </div>
      </div>
    </div>
    <div class="once-tips-box" v-if="this.cateIdOneDay == 38">
      提示：该箱每日仅可开一次，次日凌晨刷新
    </div>
    <div v-if="showNoticeBox" class="notice-box-wrap">
      <div class="notice-box">
        <div class="text">
          <van-notice-bar
            scrollable
            text="本网站所有活动均为消遣娱乐、打发无聊，请勿沉迷；禁止利用互动活动进行赌博/欺诈/作弊/刷积分等违法违规行为，若有发现，我们将取消您的活动参与资格，同一件CS:GO饰品不同磨损度可能存在外观差异较大的情况，我们仅保证开箱获得的饰品在同一磨损区间内盲盒概率不是累积的，以30%开3次并不意味着有90%的机会。"
          />
        </div>
        <div class="notice-icon"></div>
        <div @click="showNoticeBox = false" class="close-icon point"></div>
      </div>
    </div>
    <div @click="showRarityBox = !showRarityBox" class="rarity-box">
      <div class="rarity-text-box flex-c">
        <div class="left-icon"></div>
        <div class="center-text">星耀值</div>
        <div class="right-icon"></div>
      </div>
      <div v-if="showRarityBox" class="float-text">
        <div class="icon-img"></div>
        星耀值仅代表稀有度值，且星耀值越高代表饰品稀有度越高。（无任何它意）
      </div>
    </div>
    <div class="odd-box">
      <div class="odds-box">
        <div v-show="checkindex == 1" class="checktype" @click="changelist(2)">
          <img src="../../assets/images/OpenBox/checkicon.png" alt="" />
          <div>
            <p>物品</p>
          </div>
        </div>
        <div v-show="checkindex == 2" class="checktype" @click="changelist(1)">
          <img src="../../assets/images/OpenBox/checkicon.png" alt="" />
          <div>
            <p>历史</p>
          </div>
        </div>

        <div class="odds">
          <div
            class="odd-item"
            :class="`odd-item${item.level - 1}`"
            v-for="(item, index) in ItemInfo.odds_list"
            :key="index"
          >
            {{ item.odds }}
          </div>
        </div>
      </div>
    </div>
    <div class="goods-box">
      <goodsMain
        v-show="checkindex == 1"
        :DetailList="List"
        :id="this.$route.query.id"
      ></goodsMain>
      <getgoodslist
        v-show="checkindex == 2"
        :DetailList="HistoryList"
      ></getgoodslist>
    </div>

    <!-- 开奖遮罩层 -->
    <van-overlay @mousewheel.prevent.native="() => {}" :show="Tshow">
      <div :class="active == 5 ? 'overlay-wrappery' : 'overlay-wrapperx'">
        <div class="Show_box">
          <AOpenBox v-if="Tshow" :imgList="ItemShuJv" @confirmFun="GuanBi" />
        </div>
      </div>
    </van-overlay>
    <van-overlay :lock-scroll="false" :show="listshow">
      <div class="overlay-showbox">
        <div class="itembox">
          <div class="title">
            历史掉落
            <img
              @click="
                () => {
                  this.listshow = false;
                }
              "
              src="../../assets/images/new/bg7.png"
              alt=""
            />
          </div>
          <div @click="restOnClick" class="rest-img"></div>
          <div class="line"></div>
          <div class="tablebox">
            <record
              :list="recordList"
              :tableData="tableData"
              :tableRecordList="tableRecordList"
              @dropItemClick="dropItemClick"
              @pageChange="listchange"
              @rest="GetUnpackingRecord"
            >
              <template v-slot="scope">
                <div v-if="scope.item === 'get_user'">
                  <div
                    class="flex"
                    style="justify-content: flex-start; margin-left: 0.2rem"
                  >
                    <img
                      :src="scope.data.get_user.avatar"
                      class="header-img"
                      alt=""
                      style="margin-left: 0.1rem"
                    />
                    <div class="username">
                      {{ scope.data[scope.item].name }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="scope.item === 'type'"
                  style="white-space: nowrap"
                  :class="`record-item${scope.data.lv}1`"
                >
                  {{ scope.data["type_alias"] }}
                </div>
                <div
                  v-if="scope.item === 'name'"
                  :class="`record-item${scope.data.lv}1`"
                  style="text-align: center"
                >
                  <div
                    class="flex"
                    style="width: 1.6rem; justify-content: center"
                  >
                    <div style="width: 0.8rem">
                      <img
                        style="display: block; width: 75%"
                        :src="scope.data.cover"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-if="scope.item === 'bean'"
                  style="display: flex; justify-content: center"
                >
                  <div class="colorbox c1" v-show="scope.data.lv == 1"></div>
                  <div class="colorbox c2" v-show="scope.data.lv == 2"></div>
                  <div class="colorbox c3" v-show="scope.data.lv == 3"></div>
                  <div class="colorbox c4" v-show="scope.data.lv == 4"></div>
                </div>
              </template>
            </record>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="popupShow">
      <div class="overlay-my-backpack">
        <div @click="closeOnClick" class="close-btn"></div>
        <div class="title">饰品注能</div>
        <div class="bg-line bg-line1"></div>
        <div class="bg-line bg-line2"></div>
        <div class="animation-bg">
          <div class="animation-bg-box" style="transform: scale(1.5)">
            <div class="common-left-icon-box">
              <div class="left-circle">
                <div
                  :style="`--d:${360 - svgCircleAngle1}deg`"
                  class="common-test-circle"
                ></div>
              </div>

              <div class="title">饰品注能</div>
              <div v-if="svgCircleAngle1 < 180" class="sactor-mask"></div>
              <div
                v-else
                class="sactor-mask"
                :style="`background: rgba(0, 0, 0, ${
                  (360 - svgCircleAngle1) / 360
                }`"
              ></div>
            </div>
          </div>
        </div>
        <div class="backpack-box">
          <div
            v-for="item in backpackList"
            :key="item.id"
            class="backpack-item"
            @click="chooseItemOnClick(item)"
          >
            <GoodItem
              :item="item"
              :isActive="chooseGoodsIdList.includes(item.id)"
            />
          </div>
        </div>
        <div class="page-box">
          <Pagination
            :total="page_total"
            :page.sync="page_num"
            :limit.sync="per_page"
            @pagination="handleCurrentChange"
          />
        </div>
        <div class="bottom-btn-box flex-c">
          <div @click="chooseOnClick" class="btn-box">确定选择</div>
          <div @click="chooseAllOnClick" class="choose-all-box flex-c">
            <div class="check-box">
              <img
                v-show="!isSelectAll"
                src="@/assets/images/about/bag/mb-check.png"
                alt=""
                style="width: 0.12rem; margin-right: 0.05rem"
              />
              <img
                v-show="isSelectAll"
                src="@/assets/images/about/bag/mb-checked.png"
                alt=""
                style="width: 0.12rem; margin-right: 0.05rem"
              />
            </div>
            <div class="check-text">全选当前页</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  Box_detail,
  OpenBoxApi,
  pointOpenApi,
  PersonalInfo,
  BoxUnpackingRecord,
  getTypedata,
  getStatisticsData,
  Storage,
  ammunitionCheckApi,
  ammunitionOpenApi,
  cdkeyOpenApi,
} from "@/network/api.js";
import { mapState, mapMutations } from "vuex";
import goodsMain from "@/components/PubOpen/Goods.vue";
import getgoodslist from "@/components/PubOpen/getgoodslist.vue";
import goods from "./component/goods_box.vue";
import luckdrawY from "./component/luckdrawY";
import luckdrawX from "./component/luckdrawX.vue";
import {
  proBList,
  tableData,
  tableRecordList,
  statisticsData,
  statisticsList,
  typeData,
  typeList,
} from "./index.js";
import record from "@/components/newtable/index.vue";
import AOpenBox from "@/components/singleBox/AOpenBoxNew.vue";
// import leftFloat from "@/components/left-float/new.vue";
// import NewTableVue from '../NewUser/components/NewTable.vue';
import GoodItem from "@/views/Fusion/components/goodItem.vue";
import { uniqBy } from "lodash";
import { isInteger } from "lodash";
export default {
  name: "Activity_open",
  data() {
    return {
      checkindex: 1,
      current: 0,
      box_type: "", //宝箱type
      ItemInfo: {}, //宝箱信息(不完整)
      ItemData: [], //奖品信息
      givingItemData: [], //弹药开箱赠送的奖品
      ItemShuJv: [], //奖品信息
      List: [], //宝箱内物品
      luckdrawShow: false, //开奖列表显示
      show: false, //CDK遮罩层
      CDK_Data: "", //CDK
      CDK_Show: false, //按钮替换
      ItemData_CDK: {}, //CDk宝箱信息
      active: 1,
      CSnum: 0,
      Animation_checked: false,
      Tshow: false,
      JiaZhi: 0,
      KeyArr: [1, 2, 3, 4, 5],
      disabled: false,
      HistoryList: [],
      left: "-5.52",
      time: null,
      time_Set_Box_detail: null,
      // proList: ["0.8", "4.2", "50", "45"],
      proBList: proBList,
      noticeStatus: true,
      tableData: {},
      tableRecordList,
      statisticsData,
      statisticsList,
      typeData,
      typeList,
      recordList: [],
      playerShow: false, // 玩家掉落点击弹窗
      popupTab: ["最近获得", "盲盒游戏", "近期表现"],
      tabCurrent: 0,
      getList: [],
      performanceList: [],
      gameList: [],
      loading: true,
      activeTab: 0,
      typenum: 100, //类型统计的num
      nums: 1, //概率对比的数据
      statnum: 100, //掉落数据chage
      audioplay: false, //声音播放
      showNoticeBox: true,
      listshow: false,
      popupShow: false,
      backpackList: [],
      page_num: 1,
      per_page: 9,
      page_total: 1,
      chooseGoodsList: [],
      Timer1: "",
      Timer2: "",
      openDanyao: false,
      showRarityBox: false,
      showRarityBox2: false,
      Cdkey: null,
      cateIdOneDay: 0,
      lastClicked: null,

      is_open: null, //判断是否打开
    };
  },
  created() {
    this.init();
  },

  methods: {
    changeActiveOnClick(item) {
      this.clickBgMusic.load();
      this.clickBgMusic.play();
      if (this.disabled) {
        console.log("不允许操作");
        return;
      }
      this.active = item;
    },
    changelist(v) {
      this.checkindex = v;
    },
    ...mapMutations(["ChangeAudioStatus", "User"]),
    async init() {
      this.ChangeAudioStatus(true);
      this.box_type = this.$route.query.type;
      await this.Box_detail();
      // this.GetUnpackingRecord();
    },
    async restOnClick() {
      await this.GetUnpackingRecord();
      setTimeout(() => {
        this.$message.success("刷新成功");
        document.querySelectorAll(".tablebox")[0].scrollTop = 0;
      }, 0);
    },
    chooseGoodsOnClick() {
      this.backpackList = [];
      this.popupShow = true;
      this.getStorageFun();
    },
    chooseOnClick() {
      this.popupShow = false;
    },
    chooseAllOnClick() {
      if (this.isSelectAll) {
        this.chooseGoodsList = this.chooseGoodsList.filter((item) => {
          return !this.backpackList
            .map((vItem) => {
              return vItem.id;
            })
            .includes(item.id);
        });
        console.log(123, this.chooseGoodsList);
      } else {
        this.chooseGoodsList = uniqBy(
          [...this.chooseGoodsList, ...this.backpackList],
          "id"
        );
        console.log(456, this.chooseGoodsList);
      }
    },
    handleCurrentChange(e) {
      this.page = e.page;
      this.getStorageFun();
    },
    chooseItemOnClick(item) {
      if (!this.chooseGoodsIdList.includes(item.id)) {
        this.chooseGoodsList.push(item);
      } else {
        this.chooseGoodsList = this.chooseGoodsList.filter(
          (vItem) => vItem.id !== item.id
        );
      }
    },
    // handleClick() {
    //   if (!localStorage.getItem("lastClicked")) {
    //     localStorage.setItem("lastClicked", new Date().getTime());
    //   } else {
    //     let lastClickedTime = localStorage.getItem("lastClicked");
    //     const timeSinceLastClick = new Date().getTime() - lastClickedTime;
    //     const hoursSinceLastClick = timeSinceLastClick / (1000 * 60 * 60);

    //     if (hoursSinceLastClick < 24) {
    //       this.$message({
    //         message: `请等待 ${(24 - hoursSinceLastClick).toFixed(
    //           2
    //         )}小时后再试`,
    //         type: "error",
    //       });
    //       console.log(
    //         "Please wait",
    //         24 - hoursSinceLastClick,
    //         "hours before clicking again"
    //       );
    //     } else {
    //       localStorage.setItem("lastClicked", new Date().getTime());
    //     }
    //   }
    // },
    getStorageFun() {
      Storage(this.page_num, 1, 0, this.per_page).then((res) => {
        console.log(res);
        this.backpackList = res.data.data.data;
        this.page_total = res.data.data.total;
      });
    },
    closeOnClick() {
      this.popupShow = false;
    },
    mp3PlayOnClick() {
      if (this.soundStatus) {
        this.ChangeAudioStatus(false);
      } else {
        this.ChangeAudioStatus(true);
      }
    },
    tabCurrentChange(i) {
      this.tabCurrent = i;
      if (i === 0) {
        setTimeout(() => {
          this.getList = [
            {
              title: "盲盒对战15.96 > 25.39",
              name: "P250(纪念品)I天旱（久经沙场）",
              time: "九天揽月3 2023-07-11 20:20:20 ",
              price: "5.55",
            },
          ];
        }, 2000);
      } else if (i === 1) {
        setTimeout(() => {
          this.gameList = [
            {
              type: 1,
              title: "P250(纪念品)I天旱（久经沙场）",
              time: "2023-07-11 20:20:20 ",
              price: "5.55",
              boxPrice: 1.22,
              num: 1,
            },
            {
              type: 1,
              title: "P250(纪念品)I天旱（久经沙场）",
              time: "2023-07-11 20:20:20 ",
              price: "5.55",
              boxPrice: 1.22,
              num: 1,
            },
            {
              type: 1,
              title: "P250(纪念品)I天旱（久经沙场）",
              time: "2023-07-11 20:20:20 ",
              price: "5.55",
              boxPrice: 1.22,
              num: 1,
            },
            {
              type: 1,
              title: "P250(纪念品)I天旱（久经沙场）",
              time: "2023-07-11 20:20:20 ",
              price: "5.55",
              boxPrice: 1.22,
              num: 1,
            },
          ];
        }, 2000);
      } else {
        this.performanceList = [
          {
            goodImg:
              "https://steam.skinsimage.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpot621FAR17PLfYQJK9cyzhr-JkvbnJ4Tck29Y_chOhujT8om7i1bh_hE4amr7dYTEe1U6NFqBqQfvxe-50ZO86s6azXMxvCQlsymJzgv330_aLijZVA",
            name: "P250(纪念品)",
          },
        ];
      }
    },
    onChange(v) {
      // 进度条的方法
    },
    goBack() {
      this.$router.go(-1);
    },
    currentChange(i) {
      this.current = i;
      if (i == 2) {
        this.getStatData();
      }
      if (i == 3) {
        this.gettypedatalist();
      }
    },
    // 历史掉落分页change
    listchange(v) {},
    // 获取type类型数据
    numchange(i) {
      this.typenum = i;
      this.gettypedatalist(i);
    },
    gettypedatalist(num = 100) {
      let box_id = this.$route.query.id;
      getTypedata(box_id, num).then((res) => {
        let data = res.data.data;
        this.typeData = {
          list: data.map((v) => {
            return {
              type: v.level,
              theory: v.odds,
              openNum: v.count,
              currently: v.out_odds,
              typenum: this.typenum,
            };
          }),
        };
      });
    },
    // 选中掉落记录的change
    numchangestate(i) {
      this.statnum = i;
      this.getStatData(i);
    },
    // 获得掉落记录数据
    getStatData(num = 100) {
      let box_id = this.$route.query.id;
      getStatisticsData(box_id, num).then((res) => {
        let data = res.data.data;
        this.statisticsData = {
          list: data.map((v) => {
            return {
              get: v.skins.cover,
              num: v.up_count,
              total: v.total_count,
              name: v.skins.name,
              cover: v.skins.cover,
              dura_alias: v.skins.dura_alias,
              statnum: this.statnum,
            };
          }),
        };
      });
    },

    Box_detail() {
      let  params = {
        id: this.$route.query.id,
        user_id: this.$route.query.userid,
      };
      Box_detail(params).then((res) => {
        this.ItemInfo = res.data.data;
        if (res.data && res.data.data && res.data.data.cate_id) {
          this.cateIdOneDay = res.data.data.cate_id;
          this.is_open = res.data.data.is_open;
        }
        this.List = (res.data?.data?.contains || []).map((item) => {
          return {
            lv: item.level,
            ...item,
            ...item.skins,
          };
        });
        this.GetUnpackingRecord();
      });
    },

    Set_Box_detail() {
      this.time_Set_Box_detail = setInterval(() => {
        this.Box_detail();
      }, 3000);
    },

    Open_History() {
      if (this.left == "-2.5") {
        this.left = "-5.52";
      } else if (this.left == "-5.52") {
        this.left = "-2.5";
      }
    },
    dropItemClick(type = 0) {
      this.activeTab = type;
      this.GetUnpackingRecord();
    },
    // 获得历史掉落记录
    GetUnpackingRecord(funParams = {}) {
      console.log("历史掉落", this.isPointBox);
      let type_id = 1;
      if (this.isPointBox) {
        type_id = 2;
      }
      if (this.isCdkeyBox) {
        type_id = 10;
      }
      let params = {
        id: this.$route.query.id,
        type_id,
      };
      params = { ...params, ...funParams };
      BoxUnpackingRecord(params).then((res) => {
        this.HistoryList = res.data.data;
      });
    },
    //关闭CDK弹框
    GBCDK() {
      this.show = false;
      this.CDK_Data = "";
    },
    openOnClick() {
      // 开箱之前的一些校验
      if (!this?.user?.id) {
        this.$message({
          message: "请先登录",
          type: "error",
        });
        return false;
      }
      if (this.disabled) {
        return false;
      }
      this.disabled = true;
      if (this.openDanyao) {
        // 弹药开箱
        this.ammunitionOpen();
      } else if (this.isPointBox) {
        // 积分开箱
        let params = {
          id: this.$route.query.typeid,
          num: this.active,
        };
        this.commonOpenFun(pointOpenApi(params));
      } else if (this.isCdkeyBox) {
        // CDK开箱
        let params = {
          id: this.$route.query.typeid,
          cdk: this.Cdkey,
        };
        this.commonOpenFun(cdkeyOpenApi(params));
      } else if (this.cateIdOneDay == 38) {
        // 每日一箱   24小时内只能点击一次
        // this.handleClick();
        this.commonOpenFun(OpenBoxApi(this.$route.query.id, this.active));
      } else {
        // 普通开箱
        this.commonOpenFun(OpenBoxApi(this.$route.query.id, this.active));
      }
    },
    //开箱
    commonOpenFun(syncFun) {
      if (this.Animation_checked == true) {
        syncFun.then((res) => {
          if (res.data.code == 200) {
            this.Cdkey = null;
            this.ItemShuJv = res.data.data;
            this.GetPersonalInfo();
            this.ZhongJiang();
            this.Select_All();
            if (this.is_open) {
              this.Box_detail();
            }
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
            this.disabled = false;
            this.GetPersonalInfo();
          }
        });
      } else {
        syncFun.then((res) => {
          if (res.data.code == 200) {
            this.Cdkey = null;
            let Timer = null;
            setTimeout(() => {
              // this.yaoxzBgMusic.play();
              let scaleNum = 1;
              let deg = 15;
              Timer = setInterval(() => {
                deg = -deg;
                document.querySelector(
                  ".rotate-goods-box"
                ).style.transform = `rotate(${deg}deg) scale(${scaleNum})`;
                scaleNum += 0.01;
              }, 100);
            }, 100);

            setTimeout(() => {
              clearInterval(Timer);
              document.querySelector(
                ".rotate-goods-box"
              ).style.transform = `rotate(${0}deg) scale(${1})`;
              this.luckdrawShow = true;
              this.ItemData = res.data.data.filter((item) => {
                return item?.type != 9;
              });
              this.givingItemData = res.data.data.filter((item) => {
                return item?.type == 9;
              });
              this.CSnum += 1;
              this.GetPersonalInfo();
              this.Select_All();
            }, 1000);
            if (this.is_open) {
              this.Box_detail();
            }
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
            this.luckdrawShow = false;
            this.disabled = false;
            this.GetPersonalInfo();
          }
        });
      }
    },
    // 弹药开箱
    async ammunitionOpen() {
      console.log("弹药开箱");
      if (this.svgCircleAngle1 < 360) {
        this.disabled = false;
        this.$message.error("饰品注能填充不足");
        return false;
      }
      if (this.svgCircleAngle2 < 360) {
        this.disabled = false;
        this.$message.error("弹药注能填充不足,提取物品可获取弹药");
        return false;
      }
      const params = {
        in_ids: this.chooseGoodsIdList,
        box_id: this.$route.query.id,
        num: this.active,
      };
      const res = await ammunitionCheckApi(params);
      if (res.data?.code === 200) {
        const result = await this.commonOpenFun(ammunitionOpenApi(params));
        this.chooseGoodsList = [];
      } else {
        this.disabled = false;
        this.$message.error(res.data?.message);
      }
    },

    //默认全部选中
    Select_All() {
      for (let i = 0; i < this.ItemShuJv.length; i++) {
        let key = "Select";
        let value = true;
        console.log(11111, this.ItemShuJv, i, key, value);
        this.ItemShuJv[i][key] = value;
        setTimeout(() => {
          this.$refs.Select[i].style.border = "1px solid #E9B10E";
        }, 10);
      }
      this.TotalPriceCal();
    },

    //选中
    SelectItem(item, index) {
      if (item.Select) {
        delete item.Select;
        this.$refs.Select[index].style.border = "1px solid transparent";
      } else {
        let key = "Select";
        let value = true;
        item[key] = value;
        this.$refs.Select[index].style.border = "1px solid #E9B10E";
      }
      this.TotalPriceCal();
    },

    // 计算回收总价
    TotalPriceCal() {
      this.JiaZhi = 0;
      let A_Arr = this.ItemShuJv.filter((v) => {
        return v.Select == true;
      });

      for (let k = 0; k < A_Arr.length; k++) {
        this.JiaZhi += Number(A_Arr[k].bean);
      }
    },

    fromChild(v) {
      this.disabled = false;
      this.luckdrawShow = false;
    },

    //中奖
    ZhongJiang() {
      for (let j = 0; j < this.ItemShuJv.length; j++) {
        let key = "Select";
        let value = true;
        this.ItemShuJv[j][key] = value;
        this.JiaZhi = 0;
        for (let i = 0; i < this.ItemShuJv.length; i++) {
          this.JiaZhi = this.JiaZhi + Number(this.ItemShuJv[i].bean);
        }
        this.Tshow = true;
        this.GetUnpackingRecord();
      }
    },

    //关闭遮罩层
    GuanBi() {
      this.disabled = false;
      this.Tshow = false;
    },
    //筛选
    IsCover(item, index) {
      if (this.ItemShuJv[index].Select == true) {
        this.ItemShuJv[index].Select = false;
      } else {
        this.ItemShuJv[index].Select = true;
      }
      this.KeyArr = this.KeyArr.map((item) => {
        return item + 1;
      });

      this.JiaZhi = 0;

      let ZJArr = this.ItemShuJv.filter((i) => {
        return i.Select == true;
      });
      for (let i = 0; i < ZJArr.length; i++) {
        this.JiaZhi = this.JiaZhi + Number(ZJArr[i].bean);
      }
    },

    //切换组件与按钮
    childByValue(val) {
      this.luckdrawShow = val;
      this.CDK_Show = val;
      this.CDK_Data = "";
    },

    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        this.User(res.data.data);
      });
    },
  },

  beforeRouteLeave: function (to, from, next) {
    // 关闭页面的时候执行关闭这个弹窗
    this.GuanBi();
    // 同样隐藏这个开奖的动画 - 不会造成金币缺少的时候打不开箱子的问题
    this.luckdrawShow = false;

    clearInterval(this.time);
    clearInterval(this.time_Set_Box_detail);
    this.time = null;
    next();
  },
  computed: {
    ...mapState(["soundStatus", "user", "clickBgMusic", "yaoxzBgMusic"]),
    Opacity0() {
      let style = ``;
      if (this.disabled) {
        style = `opacity:0`;
      }
      return style;
    },
    isPointBox() {
      let flag = false;
      if (this.ItemInfo?.is_score_box === 1) {
        flag = true;
      }
      return flag;
    },
    isCdkeyBox() {
      let flag = false;
      if (this.ItemInfo?.is_score_box === 4) {
        flag = true;
      }
      return flag;
    },
    ShowOrHide() {
      return "left:" + this.left + "rem";
    },
    isSelectAll() {
      let flag = true;
      this.backpackList.forEach((item) => {
        if (!this.chooseGoodsIdList.includes(item.id)) {
          flag = false;
        }
      });
      return flag;
    },
    chooseGoodsIdList() {
      return this.chooseGoodsList.map((item) => item.id);
    },
    openTotalPrice() {
      let total = (this.ItemInfo.bean * this.active).toFixed(2);
      return total === "NaN" ? 0 : total;
    },
    chooseGoodsTotalPrice() {
      let total = 0;
      this.chooseGoodsList.forEach((item) => {
        total += item.bean * 1;
      });
      return total.toFixed(4);
    },
    svgCircleAngle1() {
      let angle = 0;
      if (this.openTotalPrice * 1 > 0) {
        if (this.chooseGoodsTotalPrice * 1 >= this.openTotalPrice * 1) {
          angle = 360;
        } else {
          angle = (this.chooseGoodsTotalPrice / this.openTotalPrice) * 360;
        }
      }
      console.log(123123, angle);
      return angle;
    },
    svgCircleAngle2() {
      let angle = 0;
      if (this.openTotalPrice * 1 > 0) {
        if (this.user.ammunition_energy * 1 < this.openTotalPrice * 1) {
          angle =
            ((this.user.ammunition_energy * 1) / this.openTotalPrice) * 1 * 360;
        } else {
          angle = 360;
        }
      }
      return angle;
    },
  },

  components: {
    goods,
    luckdrawY,
    goodsMain,
    getgoodslist,
    luckdrawX,
    record,
    AOpenBox,
    GoodItem,
  },
};
</script>

<style lang="scss">
.view-open-mod {
  width: 100%;
  // padding: 0 0.2rem;
  background: #020715;
  position: relative;
  .common-test-circle {
    position: absolute;
    left: -0.02rem;
    top: -0.02rem;
    width: calc(100% + 0.04rem);
    height: calc(100% + 0.04rem);
    border-radius: 50%;
    z-index: 5;
    --d: 360deg;
    background: conic-gradient(#0c0c0a var(--d), #0000 var(--d));
    transition: --d 0.5s ease-out;
    transform: rotateY(180deg);
  }
  .float-black-box {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.85);
    z-index: 67;
  }
  .float-opens-btn-box {
    position: absolute;
    left: 0.44rem;
    top: 2rem;
    z-index: 66;
  }
  .float-sound-box {
    width: 0.24rem;
    height: 0.24rem;
    position: absolute;
    right: 0.78rem;
    top: 2rem;
    z-index: 66;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .float-animation-box {
    width: 0.24rem;
    height: 0.24rem;
    position: absolute;
    right: 0.4rem;
    top: 2rem;
    z-index: 66;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .common-left-icon-box {
    width: 100%;
    height: 100%;
    background: url(../../assets/images/new/danyao-bg1.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .left-circle {
      width: 0.42rem;
      height: 0.42rem;
      border-radius: 50%;
      border: 0.02rem solid #fbb202;
      position: relative;
      box-sizing: border-box;
    }
    .title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 0.09rem;
      color: #fff;
      white-space: nowrap;
      z-index: 9;
    }
    .sactor-mask {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      z-index: 56;
      background: rgba(0, 0, 0, 0.5);
    }
  }
  .common-right-icon-box {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../../assets/images/new/danyao-bg2.png) no-repeat;
    background-size: 100% 100%;
    .right-circle {
      width: 0.42rem;
      height: 0.42rem;
      border-radius: 50%;
      border: 0.02rem solid #9898ff;
      position: relative;
      box-sizing: border-box;
    }
    .title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 0.09rem;
      z-index: 9;
      color: #fff;
      white-space: nowrap;
    }
    .sactor-mask {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      z-index: 57;
      background: rgba(0, 0, 0, 0.5);
    }
  }
  .header-box {
    width: 100%;
    height: 0.44rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.16rem;
    padding: 0 0.2rem;
    position: relative;
    z-index: 66;
    .back-box {
      width: 0.25rem;
      height: 0.14rem;
      background: url(../../assets/images/new/back-icon.png) no-repeat center;
      background-size: 100% 100%;
    }
  }
  .center-box {
    width: 100vw;
    height: 2.48rem;
    margin-top: -0.22rem;
    // margin-bottom: 0.3rem;
    background: url(../../assets/images/OpenBox/header-bg3-new.png) no-repeat
      center;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0.59rem 0 0.31rem 0;
    z-index: 64;
    // left: -0.2rem;
    .good-img {
      width: 1.58rem;
      height: 1.58rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 100;
      // margin-top: 0.1rem;
      .rotate-goods-box {
        transform: rotate(0deg);
        transition: all 0.2s;
      }
    }
  }
  .num-box {
    width: 100%;
    padding: 0 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.22rem;
    margin-top: -0.1rem;
    position: relative;
    z-index: 98;
    .num-box-item {
      width: 0.55rem;
      height: 0.26rem;
      margin: 0 0.015rem;
      flex-shrink: 0;
      background: url(../../assets/images/OpenBox/num-bg.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.1rem;
    }
    .num-box-item-active {
      background: url(../../assets/images/OpenBox/num-bg-a.png) no-repeat;
      background-size: 100% 100%;
    }
  }
  .cdkey-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.12rem;
    flex-wrap: wrap;
    .input-box {
      width: 3.06rem;
      height: 0.28rem;
      background: #08080f;
      border-radius: 0.02rem;
      border: 0.02rem solid #2f2f5e;
      display: flex;
      align-items: center;
      justify-content: center;
      .el-input {
        all: unset;
        display: block;
        width: 100%;
        height: 100%;
      }

      .el-input__inner::placeholder {
        color: rgba(191, 191, 237, 0.5);
      }
      .el-input__inner {
        all: unset;
        display: block;
        width: 100%;
        height: 100%;
        line-height: unset;
        text-align: center;
      }
    }
    .tips {
    }
  }
  .open-btn-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.08rem;
    position: relative;
    .open-btn {
      width: 1.76rem;
      height: 0.34rem;
      font-size: 0.14rem;
      background: url(../../assets/images/OpenBox/open-btn-bg.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .closebox {
      background: #878686;
    }
    .left-icon-box {
      width: 0.68rem;
      height: 0.68rem;
      position: absolute;
      left: 0.2rem;
      top: -0.17rem;
    }
    .right-icon-box {
      width: 0.68rem;
      height: 0.68rem;
      position: absolute;
      right: 0.2rem;
      top: -0.17rem;
    }
  }
  .once-tips-box {
    color: #c5bbed;
    font-size: 0.12rem;
    margin-bottom: 0.2rem;
    text-align: center;
  }
  .notice-box-wrap {
    margin-bottom: 0.18rem;
    padding: 0 0.2rem;
    .notice-box {
      width: 100%;
      height: 0.3rem;
      padding: 0.05rem 0.26rem;
      font-size: 0.1rem;
      color: #585896;
      position: relative;
      background: url(../../assets/images/new/bg9.png) no-repeat center;
      background-size: 100% 100%;
      .notice-icon {
        position: absolute;
        left: 0.09rem;
        top: 0.09rem;
        width: 0.12rem;
        height: 0.12rem;
        background: url(../../assets/images/new/bg6.png) no-repeat center;
        background-size: 100% 100%;
      }
      .close-icon {
        position: absolute;
        right: 0.12rem;
        top: 0.11rem;
        width: 0.08rem;
        height: 0.08rem;
        background: url(../../assets/images/new/bg7.png) no-repeat center;
        background-size: 100% 100%;
      }
      .text {
        .van-notice-bar {
          background-color: unset;
          color: #585896;
          line-height: 0.2rem;
          height: 0.2rem;
        }
      }
    }
  }
  .rarity-box {
    margin-bottom: 0.1rem;
    display: flex;
    justify-content: flex-end;
    position: relative;
    padding: 0 0.2rem;
    z-index: 1;
    .rarity-text-box {
      .left-icon {
        width: 0.16rem;
        height: 0.16rem;
        background: url(../../assets/images/about/user/xiyoudu.png) no-repeat
          center;
        background-size: 100% 100%;
        margin-right: 0.04rem;
      }
      .center-text {
        font-size: 0.12rem;
        color: #c5bbed;
        margin-right: 0.04rem;
      }
      .right-icon {
        width: 0.12rem;
        height: 0.12rem;
        background: url(../../assets/images/about/user/tips-icon.png) no-repeat
          center;
        background-size: 100% 100%;
      }
    }
    .float-text {
      position: absolute;
      right: 0.2rem;
      top: 0.3rem;
      font-size: 0.14rem;
      color: #c5bbed;
      width: 2.8rem;
      // height: 0.73rem;
      background: rgba(8, 8, 30, 0.9);
      border: 0.01rem solid #5d5d9b;
      padding: 0.08rem;
      border-radius: 0.04rem;
      .icon-img {
        width: 0.22rem;
        height: 0.12rem;
        background: url(../../assets/images/about/user/jianjiao.png) no-repeat;
        background-size: 100% 100%;
        position: absolute;
        right: 0.23rem;
        top: -0.12rem;
      }
    }
  }
  .odd-box {
    padding: 0 0.2rem;
    margin-bottom: 0.12rem;
    .odds-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .checktype {
        width: 0.5rem;
        height: 0.2rem;
        border: 0.01rem solid #2f2f5e;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 0.12rem;
          margin-right: 0.05rem;
        }
        p {
          font-weight: 400;
          font-size: 0.12rem;
          color: #c5bbed;
        }
      }
      .odds {
        display: flex;
        align-items: center;
        // padding-right: 0.4rem;
        .odd-item {
          margin-right: 0.12rem;
          font-size: 0.1rem;
          padding-left: 0.12rem;
          min-width: 0.42rem;
          height: 0.18rem;
          line-height: 0.18rem;
        }
        .odd-item0 {
          background: url(../../assets/images/battle/odd0.png) no-repeat;
          background-size: 100% 100%;
          color: #ff9902;
        }
        .odd-item1 {
          background: url(../../assets/images/battle/odd1.png) no-repeat;
          background-size: 100% 100%;
          color: #cc0000;
        }
        .odd-item2 {
          background: url(../../assets/images/battle/odd2.png) no-repeat;
          background-size: 100% 100%;
          color: #660099;
        }
        .odd-item3 {
          background: url(../../assets/images/battle/odd3.png) no-repeat;
          background-size: 100% 100%;
          color: #003399;
        }
      }
    }
  }
  .goods-box {
    padding: 0 0.2rem;
  }

  .overlay-wrapperx {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .Show_box {
      width: 3.35rem;
      height: 1.86rem;
      .Show_title {
        text-align: center;
        font-size: 12px;
      }
    }
  }
  .overlay-wrappery {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .Show_box {
      width: 3.35rem;
      height: 3.2rem;
      display: flex;
      flex-wrap: wrap;
      .Show_title {
        text-align: center;
        font-size: 12px;
      }
      .a-open-box-new {
        padding-top: 0.22rem;
      }
    }
  }
  .overlay-showbox {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .itembox {
      width: 3.6rem;
      border: 0.01rem solid #5d5d9b;
      background: #08081e;
      position: relative;
      .rest-img {
        width: 0.2rem;
        height: 0.2rem;
        position: absolute;
        top: 0.08rem;
        left: 0.3rem;
        background: url(../../assets/images/OpenBox/rest.png) no-repeat;
        background-size: 100% 100%;
      }
      .title {
        width: 100%;
        height: 0.38rem;
        text-align: center;
        line-height: 0.38rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 0.16rem;
        color: #dfdbff;
        position: relative;
        img {
          position: absolute;
          right: 0.2rem;
          top: 0.1rem;
          width: 0.12rem;
        }
      }
      .line {
        width: 100%;
        border: 0.01rem solid;
        border-image: linear-gradient(
            90deg,
            rgba(93, 93, 155, 0),
            rgba(93, 93, 155, 1),
            rgba(93, 93, 155, 0)
          )
          1 1;
      }
    }
    .tablebox {
      width: 3.5rem;
      margin: 0.1rem auto;
      height: 5.3rem;
      overflow-y: scroll;
      background: linear-gradient(90deg, rgba(8, 8, 30, 0) 0%, #08081e 72%);
      .header-img {
        width: 0.3rem;
        height: 0.3rem;
      }
      .username {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 0.1rem;
        color: #cccccc;
      }
      .colorbox {
        width: 0.24rem;
        height: 0.1rem;
      }
      .c1 {
        background: #ff9902;
      }
      .c2 {
        background: #cc0000;
      }
      .c3 {
        background: #660099;
      }
      .c4 {
        background: #003399;
      }
    }
  }
  .overlay-my-backpack {
    width: 3.37rem;
    min-height: 5.8rem;
    background: #08081e;
    border: 0.01rem solid #5d5d9b;
    // background: pink;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0.08rem 0.11rem 0.12rem 0.12rem;
    .close-btn {
      position: absolute;
      right: 0.12rem;
      top: 0.12rem;
      width: 0.12rem;
      height: 0.12rem;
      background: url(../../assets/images/new/close-btn.png) no-repeat;
      background-size: 100% 100%;
    }
    .title {
      text-align: center;
      margin-bottom: 0.2rem;
      font-weight: 600;
      color: #dfdbff;
      line-height: 0.22rem;
    }
    .bg-line {
      width: 3.25rem;
      height: 0.02rem;
      background: url(../../assets/images/new/line.png) no-repeat;
      background-size: 100% 100%;
      left: 0.05rem;
      top: 0.38rem;
      position: absolute;
    }
    .bg-line2 {
      top: unset;
      bottom: 0.5rem;
    }
    .animation-bg {
      width: 100%;
      padding: 0.2rem 0rem;
      margin-bottom: 0.12rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .animation-bg-box {
        width: 0.68rem;
        height: 0.68rem;
        // background: pink;
      }
    }
    .backpack-box {
      width: 100%;
      // height: 4.26rem;
      min-height: 3rem;
      //   border: 0.02rem solid #2f2f5e;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      overflow-y: auto;
      margin-bottom: 0.18rem;
      align-content: flex-start;
      .backpack-item {
        width: 1rem;
        height: 1.02rem;
        margin-right: 0.06rem;
        margin-bottom: 0.06rem;
        flex-shrink: 0;
        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
    }
    .page-box {
      width: 100%;
      height: 0.18rem;
      //   background: pink;
      margin-bottom: 0.3rem;
    }
    .bottom-btn-box {
      width: 100%;
      position: relative;
      .btn-box {
        background: linear-gradient(135deg, #242448 0%, #42426d 100%);
        border-radius: 0.02rem;
        border: 0.01rem solid #5a5a9a;
        padding: 0.03rem 0.32rem;
        font-size: 0.14rem;
        line-height: 0.2rem;
        width: fit-content;
      }
      .choose-all-box {
        position: absolute;
        top: 0;
        right: 0.1rem;
        height: 0.26rem;
        font-size: 0.12rem;
        color: #8a8ace;
      }
    }
  }
}
</style>
